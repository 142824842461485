import React, { FC, MouseEvent } from "react";
import { navigate } from "gatsby-link";
import { AnimatePresence, motion } from "framer-motion";

import LineReveal from "../Text/LineReveal/LineReveal";
import { useLanguage } from "../../hooks/useLanguage";

import { ToggleContainerVariants } from "./LanguageSwitch.animations";
import {
  LanguageSwitchWrapper,
  ToggleContainer,
  LanguageIcon,
} from "./LanguageSwitch.styles";

export interface LanguageSwitchProps {}

const LanguageSwitch: FC<LanguageSwitchProps> = ({ ...rest }) => {
  const [language, setLanguage] = useLanguage();

  const handleClick = (event: MouseEvent) => {
    const path = window.location.pathname.replace("/en", "");
    if (window.location.pathname.indexOf("/en/") === -1) {
      navigate(`/en${path}`);
    } else if (window.location.pathname.indexOf("/en/") > -1) {
      navigate(`${path}`);
    }
    // Fix this one day boi
    // @ts-ignore
    setLanguage(event.target.outerText);
  };

  return (
    <motion.div {...rest}>
      <LanguageSwitchWrapper>
        <ToggleContainer
          variants={ToggleContainerVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <LanguageIcon />
          <AnimatePresence exitBeforeEnter>
            <LineReveal
              key={`${language}Toggle`}
              //@ts-expect-error
              onClick={handleClick}
              text={language === "pl" ? "en" : "pl"}
              renderAs="li"
            />
          </AnimatePresence>
        </ToggleContainer>
      </LanguageSwitchWrapper>
    </motion.div>
  );
};
export default LanguageSwitch;
