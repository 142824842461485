import React from "react";
import {
  AnimatePresence,
  AnimateSharedLayout,
  domMax,
  LazyMotion,
} from "framer-motion";
import { ThemeProvider } from "styled-components";
import Navbar from "./src/components/Navbar/Navbar";
import Preloader from "./src/components/Preloader/Preloader";
import { LocaleContextProvider } from "./src/context/languageContext";
import { NavbarContextProvider } from "./src/context/navbarContext";
import { Base } from "./src/styles/Base";
import "./src/styles/fonts/fonts.css";
import Theme from "./src/styles/Theme";
import AgeVerification from "./src/components/AgeVerification/AgeVerification";
import { Helmet } from "react-helmet";

// Wraps application with provider components
export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={Theme}>
      <LocaleContextProvider>
        <LazyMotion features={domMax}>
          <Helmet>
            <title>Piwnice Półtorak</title>
            <meta
              name="description"
              content="Najwyższej jakości polskie wina"
            />
          </Helmet>
          <NavbarContextProvider>
            <Base />
            {element}
            <Navbar />
          </NavbarContextProvider>
          <AgeVerification />
          <Preloader />
        </LazyMotion>
      </LocaleContextProvider>
    </ThemeProvider>
  );
};

// Wraps application with layout components
export const wrapPageElement = ({ element }) => {
  return (
    <AnimateSharedLayout>
      <AnimatePresence
        exitBeforeEnter
        onExitComplete={() => {
          if (typeof window !== "undefined") {
            window.scrollTo({ top: 0 });
          }
        }}
      >
        <Base />
        {element}
      </AnimatePresence>
    </AnimateSharedLayout>
  );
};

// Prevents Scroll Update before exit animation ends
export const shouldUpdateScroll = () => false;

export function onServiceWorkerUpdateReady() {
  window.location.reload(true);
}
