import React, { FC } from "react";

import LineReveal from "../Text/LineReveal/LineReveal";

import { SocialMediaContainer } from "./SocialMedia.styles";

export interface SocialMediaProps {}

const SocialMedia: FC<SocialMediaProps> = ({ ...rest }) => {
  return (
    <SocialMediaContainer {...rest}>
      <LineReveal
        delay={1.4}
        renderAs="a"
        target="_blank"
        href="https://www.instagram.com/winnica_piwnice_poltorak/"
        text="Instagram"
      />
      <LineReveal
        delay={1.6}
        renderAs="a"
        target="_blank"
        href="https://www.facebook.com/PiwnicePoltorak"
        text="Facebook"
      />
    </SocialMediaContainer>
  );
};
export default SocialMedia;
