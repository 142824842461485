export const list = {
  visible: {
    visibility: "unset",
    opacity: 1,
    transition: { ease: "easeOut", delay: 0.4 },
  },
  hidden: {
    visibility: "unset",
    opacity: 0,
    transition: { ease: "easeOut", duration: 0.4 },
  },
  initial: {
    visibility: "hidden",
    transition: { ease: "easeOut", duration: 0.4 },
  },
};

export const shaderVariants = {
  initial: { background: "rgba(0, 0, 0, 0)" },
  visible: { background: "rgba(0, 0, 0, 1)", transition: { duration: 0.8 } },
  hidden: {
    background: "rgba(0, 0, 0, 0)",
    transition: { delay: 0.2, duration: 0.8 },
  },
};
