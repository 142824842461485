import styled from "styled-components";
import { motion } from "framer-motion";

import { media } from "../../utils/styled/responsive";
import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";

export const Container = styled(motion.div)<any>`
  position: fixed;
  z-index: 9;
  height: ${({ height }) => (height ? `${height}px` : "100vh")};
  width: ${({ animationEnded }) => (animationEnded ? 0 : "100vw")};
  right: 0;
  background: ${({ theme }) => theme.black[0]};
  pointer-events: ${({ isOpen }) => (isOpen ? "all" : "none")};
  cursor: ${({ animationEnded }) => (animationEnded ? "pointer" : "default")};

  will-change: background;

  ${media.tablet} {
    right: 0;
    width: ${({ animationEnded }) => (animationEnded ? "25vw" : "100vw")};
  }

  ${media.desktop} {
    width: ${({ animationEnded }) => (animationEnded ? "50vw" : "100vw")};
  }
`;

export const SideNav = styled(motion.nav)<any>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  height: ${({ height }) => (height ? `${height}px` : "100vh")};
  width: 100vw;

  display: grid;
  align-items: center;
  pointer-events: none;

  ${media.tablet} {
    width: 75vw;
    transform: unset;
    -webkit-transform: unset;
  }

  ${media.desktop} {
    width: 50vw;
  }
`;

export const OverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scaleX(-1);

  ${media.tablet} {
    transform: unset;
  }
`;

export const LanguageToggle = styled(LanguageSwitch)`
  position: absolute;
  left: 40px;
  bottom: 80px;
  padding: 10px;
  pointer-events: all;

  ${media.tablet} {
    display: none;
  }
`;
