export const splitByLines = (ref: HTMLElement, text: string) => {
  // String splitted by new lines with trimmed whitespace
  const textSplittedIntoLines = text
    .split(/\n/)
    .map((line: string) => line.trim());

  // Container holding our text
  const ghostContainer = ref;

  // Reset container
  ghostContainer.innerHTML = "";
  ghostContainer.style.setProperty("position", "static");

  // Current line, and outputLines
  let line = [];
  let output = [];

  // Generate lines
  textSplittedIntoLines.forEach((singleLine) => {
    if (singleLine === "") {
      output = output.concat(line.join(" "));
      output.push("__NEWLINE__");
      line = [];
      ghostContainer.innerHTML = "";
      return;
    }
    singleLine.split(" ").forEach((word) => {
      // Ads word to the ghost container
      ghostContainer.innerHTML += " " + word.trim();

      // Check if ghost content overflows
      if (ghostContainer.scrollWidth > ghostContainer.clientWidth) {
        ghostContainer.innerHTML = word;
        // Pushes new line to the array
        output = output.concat(line.join(" "));
        line = [];
      }
      line = line.concat(word);
    });
  });

  // Catch last line
  output = output.concat(line.join(" "));

  // Hide Ghost Container
  ghostContainer.style.setProperty("position", "absolute");

  // Remove empty spaces from arr
  output = output.filter((item) => item);

  return output;
};
