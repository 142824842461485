// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-containers-card-page-card-page-tsx": () => import("./../../../src/containers/CardPage/CardPage.tsx" /* webpackChunkName: "component---src-containers-card-page-card-page-tsx" */),
  "component---src-containers-contact-page-contact-page-tsx": () => import("./../../../src/containers/ContactPage/ContactPage.tsx" /* webpackChunkName: "component---src-containers-contact-page-contact-page-tsx" */),
  "component---src-containers-home-page-home-page-tsx": () => import("./../../../src/containers/HomePage/HomePage.tsx" /* webpackChunkName: "component---src-containers-home-page-home-page-tsx" */),
  "component---src-containers-premises-page-premises-page-tsx": () => import("./../../../src/containers/PremisesPage/PremisesPage.tsx" /* webpackChunkName: "component---src-containers-premises-page-premises-page-tsx" */),
  "component---src-containers-product-page-product-page-tsx": () => import("./../../../src/containers/ProductPage/ProductPage.tsx" /* webpackChunkName: "component---src-containers-product-page-product-page-tsx" */),
  "component---src-containers-wine-page-wine-page-tsx": () => import("./../../../src/containers/WinePage/WinePage.tsx" /* webpackChunkName: "component---src-containers-wine-page-wine-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

