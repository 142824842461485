import React, { FC } from "react";

import { sharedValues } from "../../utils/styled/sharedValues";

import { LineBottomVariants, LineTopVariants } from "./Hamburger.animations";
import { HamburgerWrapper, LineTop, LineBottom } from "./Hamburger.styles";

export interface HamburgerProps {
  isOpen?: boolean;
  color?: string;
}

const Hamburger: FC<HamburgerProps> = ({
  isOpen = false,
  color = sharedValues.colors.white[0],
  ...rest
}) => {
  return (
    <HamburgerWrapper
      initial={false}
      animate={isOpen ? "open" : "close"}
      {...rest}
    >
      <LineTop color={color} variants={LineTopVariants} />
      <LineBottom color={color} variants={LineBottomVariants} />
    </HamburgerWrapper>
  );
};

export default Hamburger;
