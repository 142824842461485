import React, { useState, useEffect } from "react";
import useViewportSize from "../../hooks/useViewportSize";
import { shaderVariants } from "./Menu.animations";
import {
  Container,
  OverlayWrapper,
  SideNav,
  LanguageToggle,
} from "./Menu.styles";

import BackgroundMorph from "../BackgroundMorph/BackgroundMorph";
//@ts-ignore
import List from "../List/List";
//@ts-ignore
import SocialMedia from "../SocialMedia/SocialMedia";
import { AnimatePresence } from "framer-motion";

//@ts-ignore
function Menu({ isOpen, setIsOpen, ...props }) {
  const [animationEnded, setAnimationEnded] = useState(false);
  const { size } = useViewportSize();

  useEffect(() => {
    let timeoutId: any;
    if (isOpen === true) {
      timeoutId = setTimeout(() => setAnimationEnded(true), 800);
    } else {
      setAnimationEnded(false);
    }

    return () => clearTimeout(timeoutId);
  }, [isOpen]);

  return (
    <Container
      height={size.height}
      initial="initial"
      animate={isOpen ? "visible" : "hidden"}
      variants={shaderVariants}
      onClick={(e: any) => {
        e.stopPropagation();
        setIsOpen((prev: any) => !prev);
      }}
      isOpen={isOpen}
      animationEnded={animationEnded}
      {...props}
    >
      <SideNav height={size.height}>
        <OverlayWrapper>
          <BackgroundMorph isOpen={isOpen} />
        </OverlayWrapper>
        <AnimatePresence exitBeforeEnter>
          {isOpen && (
            <>
              <SocialMedia />
              <List />
              <LanguageToggle />
            </>
          )}
        </AnimatePresence>
      </SideNav>
    </Container>
  );
}

export default React.memo(Menu);
