import React, {
  ElementType,
  FC,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { m } from "framer-motion";

import * as Styled from "./LineReveal.styles";
import { useInView } from "react-intersection-observer";
import useViewportSize from "../../../hooks/useViewportSize";
import { splitByLines } from "../utils/splitByLines";

export interface LineRevealProps {
  text: string;
  triggerOnce?: boolean;
  center?: boolean;
  longText?: boolean;
  renderAs?: ElementType;
  delay?: number;
  cName?: string;
  style?: React.CSSProperties;
}

const LineReveal: FC<LineRevealProps> = ({
  text = "Some Random Text",
  triggerOnce = false,
  center = false,
  renderAs = "p",
  delay = 0,
  cName = "",
  longText = false,
  ...rest
}) => {
  const ghostRef = useRef<HTMLElement>(null);
  const [lines, setLines] = useState([]);
  const { size } = useViewportSize();

  let lineIndex = 0;

  useLayoutEffect(() => {
    setLines(splitByLines(ghostRef.current, text));
  }, [text, size.width]);

  const { ref, inView } = useInView({
    triggerOnce: triggerOnce,
  });
  return (
    <>
      <Styled.Ghost as={renderAs} ref={ghostRef} aria-hidden="true" />
      <Styled.LinesContainer ref={ref} {...rest} key={text} aria-label={text}>
        {lines.map((line, index) => {
          if (line !== "__NEWLINE__") {
            lineIndex = lineIndex + 1;
          }
          return (
            <Styled.LineWrapper key={line + index} center={center} {...rest}>
              {line === "__NEWLINE__" ? (
                <Styled.Spacer
                  aria-hidden="true"
                  //@ts-ignore
                  as={m[renderAs]}
                  className="spacer"
                />
              ) : (
                <Styled.Line
                  //@ts-ignore
                  as={m[renderAs]}
                  className={`${cName} line`}
                  animate={inView ? "reveal" : "initial"}
                  custom={{
                    i: lineIndex,
                    delay: delay,
                    longText: longText,
                  }}
                  exit={inView && "revealExit"}
                  aria-hidden="true"
                  {...rest}
                >
                  {line}
                </Styled.Line>
              )}
            </Styled.LineWrapper>
          );
        })}
      </Styled.LinesContainer>
    </>
  );
};
export default LineReveal;
