import styled from "styled-components";
import { m } from "framer-motion";
import { media } from "../../utils/styled/responsive";

export const SocialMediaContainer = styled(m.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  & a {
    margin-right: 40px;
    pointer-events: all;
    cursor: pointer;
  }

  display: none;

  ${media.tablet} {
    display: flex;
    align-items: center;
    height: 120px;
    left: 64px;
  }

  ${media.desktop} {
    left: 80px;
  }
`;
