import { useEffect, useState } from "react";
import throttle from "../utils/throttle";

interface SizeProps {
  height: number;
  width: number;
}

export const useViewportSize = (options = { triggerOnce: false }) => {
  const [vh, setVH] = useState<number>(0);
  const [vw, setVW] = useState<number>(0);
  const size: SizeProps = {
    height: vh,
    width: vw,
  };

  function handleResize() {
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    setVH(viewportHeight);
    setVW(viewportWidth);
  }

  // Runs initial viewport width check, and adds resize listener
  useEffect(() => {
    handleResize();
    const throttledHandleResize = throttle(handleResize, 100);
    if (options.triggerOnce === false) {
      window.addEventListener("resize", throttledHandleResize);
    }
    return () => {
      window.removeEventListener("resize", throttledHandleResize);
    };
  }, [options.triggerOnce]);

  return { size };
};

export default useViewportSize;
