export const reveal = {
  initial: {
    opacity: 0,
    y: "1em",
  },
  reveal: ({ i, delay = 0, longText }) => ({
    opacity: [0, 1],
    rotate: longText ? 0 : [5, 0],
    y: ["1em", "0em"],
    transition: {
      delay: longText ? i * 0.1 + delay : i * 0.2 + delay,
      duration: 0.6,
      ease: "easeOut",
    },
  }),
  revealExit: {
    opacity: 0,
    y: "-1em",
    transition: {
      duration: 0.4,
      ease: "easeOut",
    },
  },
};
