import React, { FC, useEffect, useState } from "react";
import { useAnimation } from "framer-motion";

import useElementSize from "../../hooks/useElementSize";

import { variants } from "./BackgroundMorph.animations";
import { Container, Svg, Path, ContentWrapper } from "./BackgroundMorph.styles";
import { sharedValues } from "../../utils/styled/sharedValues";

export interface BackgroundMorphProps {
  isOpen: boolean;
  delay?: number;
  transformOrigin?: "left" | "right" | "top" | "bottom";
  fill?: string;
  instant?: boolean;
  duration?: number;
}

const BackgroundMorph: FC<BackgroundMorphProps> = ({
  isOpen = false,
  fill = sharedValues.colors.black[1],
  delay = 0,
  duration = 0.8,
  instant = false,
  transformOrigin = "left",
  ...rest
}) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const { ref, size } = useElementSize({ triggerOnce: true });
  const controls = useAnimation();

  async function open() {
    controls.start("open");
  }
  async function close() {
    controls.start("close");
  }

  useEffect(() => {
    if (shouldAnimate || instant) {
      isOpen ? open() : close();
    }
    setShouldAnimate(true);
  }, [isOpen]);

  return (
    <ContentWrapper ref={ref} {...rest}>
      <Container
        width={size.width}
        height={size.height}
        transformOrigin={transformOrigin}
      >
        <Svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 22 22"
          preserveAspectRatio="none"
        >
          <Path
            animate={controls}
            fill={fill}
            variants={variants}
            custom={{ duration: duration, delay: delay }}
          />
        </Svg>
      </Container>
    </ContentWrapper>
  );
};

export default BackgroundMorph;
