import styled from "styled-components";
import { motion } from "framer-motion";

import { sharedValues } from "../../utils/styled/sharedValues";

interface SvgWrapperProps {
  show?: {};
  hide?: {};
  isPreloader?: {};
}

export const SvgWrapper = styled(motion.svg)<SvgWrapperProps>`
  will-change: opacity, path-length;
  width: 100%;
`;

export const Path = styled(motion.path)`
  fill: none;
  stroke-width: 4;
  stroke: ${sharedValues.colors.white[0]};
`;
