export const LanguageVariants = {
  show: {
    opacity: 1,
    transiton: {
      duration: 1.2,
    },
  },
  hide: {
    opacity: 0,
    transiton: {
      duration: 1.2,
    },
  },
};
