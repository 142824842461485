import styled, { css } from "styled-components";
import { m } from "framer-motion";

import { reveal } from "./LineReveal.animations";
import { media } from "../../../utils/styled/responsive";

interface LinesContainerProps {}
interface LineWrapperProps {
  center: boolean;
}

export const Ghost = styled.div`
  height: 0;
  width: auto;
  overflow-x: scroll;
  white-space: nowrap;
  pointer-events: none;
  visibility: hidden;
`;

export const LinesContainer = styled.div<LinesContainerProps>`
  white-space: pre-wrap;
`;

export const LineWrapper = styled.div<LineWrapperProps>`
  overflow: hidden;
  width: auto;
  pointer-events: none;

  ${media.tablet} {
    ${({ center }) =>
      center &&
      css`
        text-align: center;
      `}
  }
`;

export const Line = styled(m.span)`
  white-space: nowrap;
  transform-origin: left;
  will-change: transform;
`;

export const Spacer = styled.div`
  height: 3em;
`;

Line.defaultProps = {
  initial: "initial",
  variants: reveal,
};
