import styled from "styled-components";

import { motion } from "framer-motion";
import { media } from "../../utils/styled/responsive";

interface LineProps {
  color?: string;
}

export const HamburgerWrapper = styled(motion.div)`
  position: relative;
  overflow: hidden;
  width: 24px;
  height: 16px;
  transform: scaleX(-1);
  ${media.tablet} {
    transform: scaleX(1);
  }
`;

export const Line = styled(motion.span)<LineProps>`
  position: absolute;
  background: ${({ color }) => (color ? color : "black")};
  height: 2px;
  left: 0;
  width: 24px;
  will-change: transform;
`;

export const LineTop = styled(Line)`
  top: 0;
`;

export const LineBottom = styled(Line)`
  bottom: 0;
  transform: translateX(-8px);
`;
