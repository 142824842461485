export const Theme = {
  // Colors
  black: ["#000000", "#1A1A1A"],
  white: ["#FFFFFF", "#CFCFCF", "#868686"],
  gold: "#E6CE7D",
  // Breakpoints
  breakpoints: ["768px", "992px"],
};

export default Theme;
