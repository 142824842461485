export const PathVariants = {
  show: {
    pathLength: [0, 1],
    opacity: 1,
    transition: {
      duration: 1.2,
      ease: "easeInOut",
    },
  },
  hide: {
    pathLength: [1, 0],
    opacity: 0,
    transition: {
      duration: 1.2,
      ease: "easeInOut",
    },
  },
  isPreloader: {
    opacity: [0, 1, 1, 1, 0],
    scale: [],
    pathLength: [0, 1, 1, 0],
    transition: {
      duration: 3,
      times: [0, 0.5, 0.75, 1],
      ease: "easeInOut",
    },
  },
};

export const SvgWrapperVariants = {
  show: {
    pathLength: [0, 1],
    opacity: 1,
    transition: {
      duration: 1.2,
      ease: "easeInOut",
    },
  },
  hide: {
    pathLength: [1, 0],
    opacity: 0,
    transition: {
      duration: 1.2,
      ease: "easeInOut",
    },
  },
  isPreloader: {
    opacity: [0, 1, 1, 1, 0],
    scale: [],
    pathLength: [0, 1, 1, 0],
    transition: {
      duration: 3,
      times: [0, 0.5, 0.75, 1],
      ease: "easeInOut",
    },
  },
};
