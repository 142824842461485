export const LineTopVariants = {
  open: {
    x: [0, 48, -24, 0],
    y: [0, 0, -24, 0],
    rotate: [0, 0, 45, 45],
    top: [0, 0, 7, 7],
    transition: {
      times: [0, 0.5, 0.5, 1],
      duration: 0.8,
      ease: ["easeIn", null, "easeOut"],
    },
  },
  close: {
    x: [0, 24, -24, 0],
    y: [0, 24, 0, 0],
    rotate: [45, 45, 0, 0],
    top: [7, 7, 0, 0],
    transition: {
      times: [0, 0.5, 0.5, 1],
      duration: 1,
      ease: ["easeIn", null, "easeOut"],
    },
  },
};

export const LineBottomVariants = {
  open: {
    x: [-8, 24, 24, 0],
    y: [0, 0, -24, 0],
    rotate: [0, 0, -45, -45],
    bottom: [0, 0, 7, 7],
    transition: {
      times: [0, 0.5, 0.5, 1],
      duration: 1,
      ease: ["easeIn", null, "easeOut"],
    },
  },
  close: {
    x: [0, -24, -24, -8],
    y: [0, 24, 0, 0],
    rotate: [-45, -45, 0, 0],
    bottom: [7, 7, 0, 0],
    transition: {
      times: [0, 0.5, 0.5, 1],
      duration: 0.8,
      ease: ["easeIn", null, "easeOut"],
      delay: 0.3,
    },
  },
};
