import styled, { css } from "styled-components";
import { motion } from "framer-motion";

interface ContainerProps {
  transformOrigin: "left" | "right" | "top" | "bottom";
  width: number;
  height: number;
}
interface PathProps {
  fill: string;
}

export const ContentWrapper = styled.div`
  height: 100%;
`;

export const Container = styled.div<ContainerProps>`
  ${({ transformOrigin }) =>
    transformOrigin === "left" &&
    css`
      height: 100%;
    `}

  ${({ transformOrigin }) =>
    transformOrigin === "right" &&
    css`
      height: 100%;
      transform: scaleX(-1);
    `}


  ${({ transformOrigin, width, height }) =>
    transformOrigin === "bottom" &&
    css`
      transform-origin: right top;
      width: ${`${height}px`};
      height: ${`${width}px`};
      transform: ${`rotate(-90deg) translateY(-${height}px)`};
    `}


    ${({ transformOrigin, width, height }) =>
    transformOrigin === "top" &&
    css`
      transform-origin: right top;
      width: ${`${height}px`};
      height: ${`${width}px`};
      transform: ${`rotate(-90deg) translateY(-${height}px) translateX(-${height}px) scaleX(-1)`};
    `}
`;

export const Svg = styled(motion.svg)`
  width: 100%;
  height: 100%;
`;

export const Path = styled(motion.path)<PathProps>`
  transform-origin: left !important;
  fill: ${({ fill }) => fill};
  will-change: d;
`;
