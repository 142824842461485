export const variants = {
  open: ({ delay, duration }) => ({
    d: [
      "M 0 24 L 0 0 L 1 0 C 1 8 1 16 1 24 L 0 24 Z",
      "M 0 24 L 0 0 L 9.143 0 C 18.286 0 18.286 24 9.143 24 L 0 24 Z",
      "M 0 24 L 0 0 L 24 0 C 24 8 24 16 24 24 L 0 24 Z",
    ],
    scaleX: [0, 1, 1],
    transition: {
      ease: ["easeIn", "easeOut"],
      duration: duration,
      delay: delay,
    },
  }),
  close: ({ duration }) => ({
    d: [
      "M 0 24 L 0 0 L 24 0 C 24 8 24 16 24 24 L 0 24 Z",
      "M 0 24 L 0 0 L 16.5 0 C 9 5.5 8.5 17.5 16.5 24 L 0 24 Z",
      "M 0 24 L 0 0 L 1 0 C 1 8 1 16 1 24 L 0 24 Z",
    ],
    scaleX: [1, 1, 0],
    transition: {
      ease: ["easeIn", "easeOut"],
      duration: duration,
    },
  }),
};
