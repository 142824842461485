import styled from "styled-components";
import { m } from "framer-motion";

import { media } from "../../utils/styled/responsive";

export const ListContainer = styled(m.div)`
  position: absolute;
  height: auto;
  display: grid;
  gap: 24px;
  & a {
    pointer-events: all;
    cursor: pointer;
  }

  right: 40px;
  transform: translateY(12.5vh);
  text-align: right;

  ${media.tablet} {
    right: unset;
    transform: unset;
    text-align: left;
    left: 64px;
  }

  ${media.desktop} {
    left: 80px;
  }
`;
