import { useState, useEffect, WheelEvent } from "react";
import { globalHistory } from "@reach/router";
import { useViewportScroll } from "framer-motion";
import throttle from "../utils/throttle";

export const useScrollDirection = () => {
  const [scrollDown, setScrollDown] = useState(false);

  // Backup for mobile devices
  const { scrollY } = useViewportScroll();

  const update = () => {
    if (scrollY.get() < scrollY?.getPrevious()) {
      setScrollDown(false);
    } else if (scrollY.get() > 100 && scrollY.get() > scrollY.getPrevious()) {
      setScrollDown(true);
    }
  };

  useEffect(() => {
    if (window.screen.width <= 768) {
      return scrollY.onChange(() => update());
    }
  }, []);

  // Reset state after route change
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") setScrollDown(false);
    });
  }, [scrollDown]);

  useEffect(() => {
    // Simply set states if user scrolls down / up
    function updateScrollDir(e: WheelEvent<HTMLDivElement>) {
      setScrollDown(e.deltaY > 0 ? true : false);
    }
    // Listen for wheel event & reduce amount of possible triggers with debounce
    window.addEventListener("wheel", throttle(updateScrollDir, 500));

    return () => {
      window.removeEventListener("wheel", throttle(updateScrollDir, 500));
    };
  }, []);

  return { scrollDown, setScrollDown };
};
