import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import useViewportSize from "../../hooks/useViewportSize";
import LogoIcon from "../LogoIcon/LogoIcon";
import { Container, LogoWrapper } from "./Preloader.styles";
import { v4 as uuidv4 } from "uuid";

function Preloader() {
  const [isComplete, setIsComplete] = useState(false);
  const { size } = useViewportSize({
    triggerOnce: true,
  });

  useEffect(() => {
    const delayedStateUpdate = setTimeout(() => setIsComplete(true), 3000);
    return () => {
      clearTimeout(delayedStateUpdate);
    };
  }, []);

  return (
    <AnimatePresence>
      {!isComplete && (
        <Container
          key={uuidv4()}
          vh={size.height}
          exit={{ opacity: 0 }}
          transition={{ duration: 1.2, ease: "easeInOut" }}
        >
          <LogoWrapper>
            <LogoIcon variant="isPreloader" />
          </LogoWrapper>
        </Container>
      )}
    </AnimatePresence>
  );
}

export default React.memo(Preloader);
