import React, { createContext, useState } from "react";
export const LocaleContext = createContext("pl");

export const LocaleContextProvider = ({ children }) => {
  const [locale, setLocale] = useState("pl");

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  );
};
