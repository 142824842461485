import styled from "styled-components";
import { motion } from "framer-motion";

import LangSVG from "../../assets/svg/language.svg";

export const LanguageSwitchWrapper = styled(motion.div)`
  overflow: hidden;
`;

export const ToggleContainer = styled(motion.div)`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 16px;
  grid-template-columns: auto 50px;
`;

export const LanguageIcon = styled(LangSVG)`
  fill: white;
  height: 26px;
`;
