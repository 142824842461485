import React, { FC, useContext, useState } from "react";

import { useLanguage } from "../../hooks/useLanguage";
import { useScrollDirection } from "../../hooks/useScrollDirection";
//@ts-ignore
import { NavbarContext } from "../../context/navbarContext";

import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";
import Hamburger from "../Hamburger/Hamburger";
import LogoIcon from "../LogoIcon/LogoIcon";
//@ts-ignore
import Menu from "../Menu/Menu";

import { LanguageVariants } from "./Navbar.animations";
import {
  NavbarWrapper,
  ButtonsContainer,
  Inner,
  MenuButton,
  LanguageSwitchWrapper,
  LogoIconWrapper,
} from "./Navbar.styles";

export interface NavbarProps {}

const Navbar: FC<NavbarProps> = ({ ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { scrollDown, setScrollDown } = useScrollDirection();
  const [language] = useLanguage();
  const linkPath = language === "pl" ? "/" : "/en/";
  const { navbar } = useContext(NavbarContext);

  const handleClick = () => {
    setScrollDown(false);
    setIsOpen(!isOpen);
  };

  if (isOpen) {
    document.querySelector("html")!.style.overflow = "hidden";
  } else {
    document.querySelector("html")!.style.overflow = "visible";
  }

  return (
    <NavbarWrapper {...rest}>
      <Inner>
        <ButtonsContainer>
          <MenuButton onClick={() => handleClick()}>
            <Hamburger isOpen={isOpen} />
            <li>menu</li>
          </MenuButton>
          <LanguageSwitchWrapper
            initial="show"
            animate={navbar.shouldHide && scrollDown ? "hide" : "show"}
            variants={LanguageVariants}
            custom={1}
          >
            <LanguageSwitch />
          </LanguageSwitchWrapper>
        </ButtonsContainer>

        <LogoIconWrapper to={linkPath}>
          <LogoIcon
            variant={navbar.shouldHide && scrollDown ? "hide" : "show"}
          />
        </LogoIconWrapper>
      </Inner>
      <Menu isOpen={isOpen} setIsOpen={setIsOpen} />
    </NavbarWrapper>
  );
};
export default Navbar;
