import { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";

export const useElementSize = (options = { triggerOnce: false }) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const size = {
    width: width,
    height: height,
    scrollWidth: scrollWidth,
    scrollHeight: scrollHeight,
  };

  function handleResize() {
    if (ref.current === null) {
      return;
    }
    const elementHeight = ref.current.offsetHeight;
    const elementWidth = ref.current.offsetWidth;
    setHeight(elementHeight);
    setWidth(elementWidth);

    const elementScrollHeight = ref.current.scrollHeight;
    const elementScrollWidth = ref.current.scrollWidth;
    setScrollHeight(elementScrollHeight);
    setScrollWidth(elementScrollWidth);
  }

  /////////////////////////
  // Add Resize listener
  ////////////////////////

  useEffect(() => {
    handleResize();
    const debouncedHandleResize = debounce(handleResize, 100);

    if (options.triggerOnce === false) {
      window.addEventListener("resize", debouncedHandleResize);
    }
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, [options.triggerOnce, height, width, scrollHeight, scrollWidth]);

  return { ref, size };
};

export default useElementSize;
