import { navigate } from "@gatsbyjs/reach-router";
import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { useLanguage } from "../../hooks/useLanguage";
import LineReveal from "../Text/LineReveal/LineReveal";
import { ListContainer } from "./List.styles";

function List({ ...props }) {
  const [language] = useLanguage();

  const data = useStaticQuery(graphql`
    {
      pl: allContentfulMenu(
        filter: { node_locale: { eq: "pl" } }
        sort: { fields: [updatedAt], order: ASC }
      ) {
        edges {
          node {
            linkName
            url
          }
        }
      }
      en: allContentfulMenu(
        filter: { node_locale: { eq: "en" } }
        sort: { fields: [updatedAt], order: ASC }
      ) {
        edges {
          node {
            linkName
            url
          }
        }
      }
    }
  `);

  const localizedData = language === "pl" ? data.pl : data.en;
  const localizedShop = language === "pl" ? "Sklep" : "Shop";

  const handleRouteChange = (e, url) => {
    e.preventDefault();
    setTimeout(() => {
      navigate(url);
    }, 800);
  };

  return (
    <ListContainer {...props}>
      <Link to={"https://sklep-piwnicepoltorak.pl"}>
        <LineReveal delay={0.4} renderAs="h3" text={localizedShop} />
      </Link>
      {localizedData.edges.map((edge, index) => {
        return (
          <Link
            to={edge.node.url}
            key={edge.node.url + index}
            onClick={(e) => handleRouteChange(e, edge.node.url)}
          >
            <LineReveal
              delay={0.55 + index * 0.15}
              renderAs="h3"
              key={edge.node.linkName}
              text={edge.node.linkName}
            />
          </Link>
        );
      })}
    </ListContainer>
  );
}

export default React.memo(List);
