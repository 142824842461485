interface Props {
  callback: Function;
  limit: number;
}

export default function throttle(callback: Function, limit: number) {
  let wait = false;
  return function(...args: any[]) {
    if (!wait) {
      callback(...args);
      wait = true;
      setTimeout(function() {
        wait = false;
      }, limit);
    }
  };
}
