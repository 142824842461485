import React, { FC } from "react";
import { m } from "framer-motion";

export interface FadeInOutProps {
  children: React.ReactNode;
}

const FadeInOut: FC<FadeInOutProps> = ({ children }) => {
  return (
    <m.div
      key={"test"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.6, type: "tween" }}
    >
      {children}
    </m.div>
  );
};
export default FadeInOut;
