import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "gatsby";

import { media } from "../../utils/styled/responsive";

export const NavbarWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: auto;
  top: 0;
  left: 0;
  z-index: 10;
  will-change: transform;
  height: 104px;
`;

export const Inner = styled.div`
  position: fixed;
  display: grid;
  align-content: center;
  pointer-events: none;
  z-index: 10;

  left: 32px;
  right: 32px;
  height: 104px;

  ${media.tablet} {
    height: 120px;
    left: 64px;
    right: 64px;
  }

  ${media.desktop} {
    height: 160px;
    left: 80px;
    right: 80px;
  }
`;
export const LogoIconWrapper = styled(Link)`
  display: grid;
  place-items: center;
  cursor: pointer;
  pointer-events: all;

  width: 40px;
  justify-self: left;

  ${media.tablet} {
    justify-self: center;
  }

  ${media.desktop} {
    width: 48px;
  }
`;

export const ButtonsContainer = styled.div`
  pointer-events: all;
  cursor: pointer;
  position: absolute;
  align-self: center;
  width: min-content;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;

  right: 0;

  ${media.tablet} {
    right: unset;
    left: 0;
    gap: 40px;
  }
`;

export const MenuButton = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  & li {
    order: -1;
  }
  padding: 24px 0 24px 24px;

  ${media.tablet} {
    & li {
      order: unset;
    }

    padding: 24px 24px 24px 0;
  }
`;

export const LanguageSwitchWrapper = styled(motion.div)`
  display: none;

  ${media.tablet} {
    display: unset;
  }
`;
