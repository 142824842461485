import styled from "styled-components";

export const AgeVerificationWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100vh;
  background: black;
  padding: 40px;
`;

export const FlexWrapper = styled.div`
  display: grid;
  place-items: center;
  gap: 80px;
`;
export const SelectWrapper = styled.div`
  display: grid;
  place-items: center;
  grid-auto-flow: column;
  gap: 24px;
  cursor: pointer;
`;
