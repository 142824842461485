export const breakpoints = {
  mobile: 375,
  mobileLand: 576,
  tablet: 768,
  desktop: 992,
  desktopLarge: 1920,
};

const customMediaQuery = (minWidth: number) =>
  `@media (min-width: ${minWidth}px)`;

// Pass in pixels
export const media = {
  mobile: customMediaQuery(breakpoints.mobile),
  mobileLand: customMediaQuery(breakpoints.mobileLand),
  tablet: customMediaQuery(breakpoints.tablet),
  desktop: customMediaQuery(breakpoints.desktop),
  desktopLarge: customMediaQuery(breakpoints.desktopLarge),
};
