import React, { FC, useState } from "react";
import { AnimatePresence } from "framer-motion";
import FadeInOut from "../Animations/FadeInOut/FadeInOut";

import LineReveal from "../Text/LineReveal/LineReveal";

import * as S from "./AgeVerification.styles";

export interface AgeVerificationProps {}

const AgeVerification: FC<AgeVerificationProps> = () => {
  const [isAdult, setIsAdult] = useState(localStorage.getItem("age"));

  const handleClick = () => {
    localStorage.setItem("age", "true");
    setIsAdult("true");
  };

  return (
    <>
      <AnimatePresence>
        {isAdult !== "true" && (
          <FadeInOut>
            <S.AgeVerificationWrapper>
              <S.FlexWrapper>
                <LineReveal
                  renderAs="h3"
                  text="Czy masz ukończone 18 lat
i oświadczasz, że jesteś pełnoletni?"
                />

                <S.SelectWrapper>
                  <a onClick={handleClick}>TAK</a>
                  <a href="https://google.com/">NIE</a>
                </S.SelectWrapper>
              </S.FlexWrapper>
            </S.AgeVerificationWrapper>
          </FadeInOut>
        )}
      </AnimatePresence>
    </>
  );
};
export default AgeVerification;
