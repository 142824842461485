import React, { FC } from "react";

import { SVG_PATHS } from "./svgPaths";
import { PathVariants, SvgWrapperVariants } from "./LogoIcon.animations";
import { SvgWrapper, Path } from "./LogoIcon.styles";

export interface LogoIconProps {
  variant: "show" | "hide" | "isPreloader";
}

const LogoIcon: FC<LogoIconProps> = ({ variant = "show", ...rest }) => {
  return (
    <SvgWrapper
      animate={variant}
      variants={SvgWrapperVariants}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 97 78"
      {...rest}
    >
      {SVG_PATHS.map((path) => (
        <Path key={path} variants={PathVariants} d={path} />
      ))}
    </SvgWrapper>
  );
};
export default LogoIcon;
