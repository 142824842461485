import styled from "styled-components";
import { m } from "framer-motion";

interface ContainerProps {
  vh: number;
}

export const Container = styled(m.div)<ContainerProps>`
  position: fixed;
  z-index: 10000;
  width: 100vw;
  height: ${({ vh }) => (vh ? `${vh}px` : "100vh")};
  background: ${({ theme }) => theme.black[0]};
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  pointer-events: none;
`;

export const LogoWrapper = styled(m.div).attrs(() => ({
  animate: { scale: [1, 2] },
  transition: {
    duration: 4,
    times: [0, 0.5, 0.75, 1],
    ease: "easeInOut",
  },
}))`
  width: 64px;
`;
