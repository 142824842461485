import { useContext, useEffect } from "react";
// Fix when converting to typescript
//@ts-ignore
import { LocaleContext } from "../context/languageContext";

export const useLanguage = () => {
  const { locale, setLocale } = useContext(LocaleContext);
  useEffect(() => {
    if (window.location.pathname.indexOf("/en/") === -1) {
      setLocale("pl");
    } else {
      setLocale("en");
    }
  }, [setLocale]);
  return [locale, setLocale];
};
