export const ToggleContainerVariants = {
  initial: {
    opacity: 0,
    y: "1em",
  },
  animate: () => ({
    opacity: [0, 1],
    y: "0em",
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  }),
  exit: {
    opacity: 0,
    y: "-1em",
    transition: {
      duration: 0.4,
      ease: "easeOut",
    },
  },
};
