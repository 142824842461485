import { createGlobalStyle } from "styled-components";

export const Base = createGlobalStyle`

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  scroll-behavior: smooth;
  overscroll-behavior: none;
  -ms-overflow-style: none;
  font-size: 16px;
  background:${({ theme }) => theme.black[0]};
  color:${({ theme }) => theme.white[0]};
}


`;
